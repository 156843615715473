// src/App.js
import React from 'react';
import SendForm from './SendForm';
import './App.css'; // Make sure to create an App.css file for styling

const App = () => {
  return (
    <div className="App">
      <SendForm />
    </div>
  );
};

export default App;
