// src/components/SendForm.jsx

import React, { useState, useEffect } from 'react';
import { fromHex, toBech32 } from '@cosmjs/encoding';
import Select, { components } from 'react-select';
import synergyLogo from './images/synergy.png';
import beraLogo from './images/berachain3.png';
import usdcimg from './images/usdc.png';
import bend from './images/bend.png';
import berps from './images/berps.png';
import bex from './images/bex.png';
import bhoney from './images/bhoney.png';
import honeywbtc from './images/honeywbtc.png';
import honeyweth from './images/honeyweth.png';
import honey from './images/honey.png';
import honeyusdc from './images/honeyusdc.png';
import honeywbera from './images/honeywbera.png';
import vdhoney from './images/vdhoney.png';
import btcLogo from './images/btc.png';
import ethLogo from './images/ethereum-logo.svg';
import bscLogo from './images/bsc-logo.svg';
import checkimg from './images/check.svg';
import metamaskLogo from './images/metamask.png';
import NetworkSelectFrom from './NetworkSelectFrom';
import NetworkSelectAssetEth from './NetworkSelectAssetEth';
import NetworkSelectAssetBsc from './NetworkSelectAssetBsc';


const SendForm = () => {

  const [hexInput, setHexInput] = useState('');
  const [prefix, setPrefix] = useState('storyvaloper');
  const [bech32Address, setBech32Address] = useState('');
  const [error, setError] = useState(null);

  const handleConvert = () => {
    try {
      if (!hexInput) {
        throw new Error("Please enter a HEX address.");
      }
      if (!prefix) {
        throw new Error("Please enter a Prefix.");
      }      
      const bech32Addr = toBech32(prefix, fromHex(hexInput));
      setBech32Address(bech32Addr);
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>  

    <div className="mainTop">
      Bech32 Tool from <img src={synergyLogo} /> <a href="https://www.synergynodes.com" target="_blank">Synergy Nodes</a>
    </div>

    <div className='mainBox'>
    

        <div className='oneBox'>
        <div className='oneRightBottom'>
        Enter Prefix:
        <div className='divTo'>
          <input
            className='inputClass'
            type="text" 
            value={prefix} 
            onChange={(e) => setPrefix(e.target.value)}
          /> <br /><br />
        </div>        
        Enter HEX:
        <div className='divTo'>        
          <input
            className='inputClass'
            type="text" 
            value={hexInput} 
            onChange={(e) => setHexInput(e.target.value)}
            placeholder='Enter HEX'
          /> <br />
        </div>
      </div>  
        </div>

        {error && <p className="error">{error}</p>}
        {bech32Address && <p className="result">Result: {bech32Address}</p>}

        <button onClick={handleConvert}>Convert to Bech32</button>





    </div>

        <div className='mainBoxblack'>
          <p>Command to get Validator HEX Address :</p>
          <div className="command">
            curl -s http://localhost:26657/status | jq -r .result.validator_info.address
          </div>
        </div>


    </div>


  );
};

export default SendForm;
