import React, { useState } from 'react';
import './Modal.css'; // Create a Modal.css file for styling
import zetaLogo from './images/zeta-logo.svg';
import ethLogo from './images/ethereum-logo.svg';
import bscLogo from './images/bsc-logo.svg';

const NetworkSelectFrom = ({ isOpen, onClose, onSelectNetwork }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-box">
        <div className="modal-header">
          <h2>Select Network</h2>
          <button onClick={onClose} className="close-button">&times;</button>
        </div>
        <div onClick={() => onSelectNetwork('Ethereum')} className="network-option">
          <img src={ethLogo} alt="Ethereum Logo" />
          Ethereum Network
        </div>
        <div onClick={() => onSelectNetwork('BSC')} className="network-option">
          <img src={bscLogo} alt="BSC Logo" />
          BSC Network
        </div>
      </div>
    </div>
  );
};

export default NetworkSelectFrom;
